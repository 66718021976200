import React from 'react'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import { graphql } from 'gatsby'
import { Heading, Flex, Box, Text, Image } from 'rebass'
import Link from '../components/base/Link'
import Hero from '../components/base/Hero'
import BenefitsIcon from '../assets/icons/benefits.svg'

const PartnerPage = ({ data }) => (
  <>
    <HelmetDatoCms seo={data.datoCmsPartnerInfo.seoMetaTags} />
    <Hero fluid={data.datoCmsPartnerInfo.heroImage.fluid}>
      <Heading as="h1" variant="hero.heading">{data.datoCmsPartnerInfo.heading}</Heading>
      <Heading as="h2" variant="hero.subheading">{data.datoCmsPartnerInfo.subheading}</Heading>
      <div>
        <Link
          to="/contact"
          variant="button"
          sx={{ px: [2, 6], py: 2, display: 'inline-block' }}
        >
          Get in touch
        </Link>
      </div>
    </Hero>
    <Box variant="fixed">
      <Box my={5}>
        <Heading
          as="h3"
          variant='heading'
          fontWeight={500}
          lineHeight={1.6}
        >
          {data.datoCmsPartnerInfo.intro}
        </Heading>
      </Box>
      <Flex my={5} mx={-2} justifyContent="center" flexWrap="wrap">
        {data.datoCmsPartnerInfo.products.map(product =>
          <Box
            key={product.id}
            mx={2}
            variant="card"
          >
            <Flex alignItems="center" mb={3}>
              <Heading as="h5" variant='subheading' sx={{ flex: 1 }}>{product.name}</Heading>
              <Image src={product.icon.url} />
            </Flex>
            <Text
              dangerouslySetInnerHTML={{
                __html: product.descriptionNode.childMarkdownRemark.html,
              }}
            />
          </Box>
        )}
      </Flex>
      <Box my={4}>
        <Text textAlign="center">
          Have something else in mind?
          {' '}
          <Link to="/contact">Get in touch</Link> with us for a bespoke product.
        </Text>
      </Box>
    </Box>
    <Box bg="snowWhite">
      <Box variant="fixed" py={[5, 6]}>
        <Flex mb={3} flexDirection={['column-reverse', 'row']} alignItems="flex-start">
          <Heading as="h3" variant='heading' sx={{ flex: 1, my: [2, 0] }}>Partnership benefits</Heading>
          <Image src={BenefitsIcon} />
        </Flex>
        <Text
          dangerouslySetInnerHTML={{
            __html: data.datoCmsPartnerInfo.benefitsNode.childMarkdownRemark.html,
          }}
        />
      </Box>
    </Box>
    <Box variant="fixed" py={[5, 6]}>
      <Heading as="h3" variant='heading'>What do our partners say?</Heading>
      <Flex justifyContent="space-evenly" my={4} mx={[0, -2, -4]} flexDirection={['column', 'row']}>
        {data.datoCmsPartnerInfo.quotes.map(partner =>
          <Box
            key={partner.id}
            width={[1, 1/3]}
            mx={[0, 2, 4]}
          >
            <Box height={100}>
              <Image src={partner.logo.url} />
            </Box>
            <Text
              dangerouslySetInnerHTML={{
                __html: partner.quoteNode.childMarkdownRemark.html,
              }}
            />
          </Box>
        )}
      </Flex>
    </Box>
    <Box bg="snowWhite">
      <Flex variant="fixed" py={[5, 6]} flexDirection={['column', 'row']}>
        <Box width={[1, 1/2]} mx={[0, 3, 6]}>
          <Heading
            as="h3"
            fontSize={[2, 3, 4]}
            color="primaryHighlight"
            fontWeight="bold"
            lineHeight={1.3}
            sx={{ my: 5 }}
          >
            {data.datoCmsPartnerInfo.proposition}
          </Heading>
          <Link
            to="/contact"
            variant="button"
            sx={{ px: [2, 4], py: 2, display: 'inline-block' }}
          >
            Get in touch
          </Link>
        </Box>
        <Box width={[1, 1/2]} mx={[0, 3, 6]}>
          {data.datoCmsPartnerInfo.features.map(feature =>
            <Box key={feature.id} my={2}>
              <Heading as="h3" variant='heading'>{feature.title}</Heading>
              <Text
                  dangerouslySetInnerHTML={{
                      __html: feature.contentNode.childMarkdownRemark.html,
                  }}
              />
            </Box>
          )}
        </Box>
      </Flex>
    </Box>
  </>
)

export default PartnerPage

export const query = graphql`
  query PartnerInfoQuery {
    datoCmsPartnerInfo {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      id
      heroImage {
        fluid(imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      heading
      subheading
      intro
      products {
        id
        name
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
        icon {
          url
        }
      }
      benefitsNode {
        childMarkdownRemark {
          html
        }
      }
      quotes {
        id
        quoteNode {
          childMarkdownRemark {
            html
          }
        }
        logo {
          url
        }
      }
      proposition
      features {
        id
        title
        contentNode {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`
